import { isValidElement, useEffect } from "react";
import { withTranslation } from "react-i18next";
/* Config */
import { ChecklistItemStatus } from "../../../config";
/* Atoms */
import Icon from "../../../atoms/Icon/Icon";
import ReportTemplateBlock from "./Block";
import ReportTemplateLabel from "./Label";

const ReportTemplateChecklist = ({ t: __, props, children, checklistItems = [], groups = [], fetus = 0, getPlaceholderWithProps, setEditChecklistDialogIsOpen = () => { }, addComponentChecklistAssoc = () => { }, removeComponentChecklistAssoc = () => { } }) => {
  const componentUID = props.id || "fetal_anatomy";

  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const checklistItemsOfFetus = checklistItems.map((items) => items[fetus]);
  const allowedSlugs = [
    ...props.items?.split("|").map(slug => slug.trim()),
    ...(componentUID === "fetal_anatomy" ? checklistItemsOfFetus.filter(item => !item.active).map(item => item.slug) : []), // add items coming from optional views only to the fetal anatomy checklist
  ];

  const normalizeStatus = (status) => {
    if (!status) return "";
    if (ChecklistItemStatus[status]) return status;
    return Object.entries(ChecklistItemStatus[status] || {}).find(([key, value]) => value.synonyms?.includes(status))?.[0];
  }

  const groupElements = Array.isArray(children) ? children.filter(child => isValidElement(child) && child.props?.type === "group") : [];

  const itemsToDisplay = checklistItemsOfFetus
    .filter(item => item && item.visible && allowedSlugs.includes(item.slug))
    .sort((a, b) => {
      if (a.order && b.order) return a.order - b.order;
      return a.label[currentLanguage] < b.label[currentLanguage] ? -1 : 1
    });

  useEffect(() => {
    addComponentChecklistAssoc(componentUID, itemsToDisplay.map(item => item.slug));
    return () => {
      removeComponentChecklistAssoc(componentUID);
    }
  }, [componentUID, itemsToDisplay]);

  const groupCommentsSlugPrefix = `checklist.${
    componentUID === "fetal_anatomy" ? "" : componentUID + "." // for backward compatibility we keep fetal_anatomy empty
  }group.`;
  
  return (!!itemsToDisplay.length &&
    <div className="checklist checklist_v2">
      {groupElements.map((group, index) => {
        const groupComments = Object.fromEntries(groups.map(g => [g.slug , getPlaceholderWithProps({
          data: `${groupCommentsSlugPrefix}${g.slug}.${normalizeStatus(group.props.status ?? group.props.props.status)}`
        })?.[fetus]?.comment]));
      
        return (
          <ReportTemplateChecklistGroup
            key={group.props.status || group.label || group.props.label || index}
            props={{...group.props, status: normalizeStatus(group.props.status ?? group.props.props.status)}}
            componentUID={componentUID}
            fetus={fetus}
            items={itemsToDisplay.filter(item => item.status === normalizeStatus(group.props.status ?? group.props.props.status))}
            allowedSlugs={allowedSlugs}
            groups={groups}
            groupComments={groupComments}
            setEditChecklistDialogIsOpen={setEditChecklistDialogIsOpen}
          />
        );
      })}
    </div>
  );
}


const ReportTemplateChecklistGroup = withTranslation()(({t: __, props, componentUID, fetus, items, allowedSlugs, groups, groupComments, setEditChecklistDialogIsOpen }) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();

  const hasNotes = groups.some(group => !!groupComments[group.slug]);
  const printable = !!items.filter(item => item.status !== "not_applicable").length || !!groupComments.length || hasNotes;
  
  return <div className={`checklist_group ${printable ? "" : "not-printable"}`}>
    <label>{props.label || props.props.label}</label>
    {groups.map(group => {
      const groupItems = items.filter(item => item.group_id === group.id);
      const hasGroupNotes = !!groupComments[group.slug];
      return (!groupItems.length && !hasGroupNotes) ? false : (
        <div key={group.id} onClick={() => setEditChecklistDialogIsOpen({componentUID, slug: group.slug, allowedSlugs, fetus})}>
          <ReportTemplateBlock props={{layout: "columns", "auto-hide": "true"}}>
            <ReportTemplateBlock props={{width: "20%"}}>
              <ReportTemplateLabel props={{}}>
                {group.name}
              </ReportTemplateLabel>
            </ReportTemplateBlock>
            <ReportTemplateBlock>
              {groupItems.map(item => (
                <span
                  key={item.id}
                  className="checklist_item has-printable-value"
                >
                  {item.label?.[currentLanguage]}
                  {!!item.comment && <span className="checklist_comment">({item.comment})</span>}
                  {item.children?.length && (
                    <span className="children">
                      {item.children.map(child =>
                        <span key={child.id}>
                          <Icon name="right" />
                          {child.label?.[currentLanguage]}
                        </span>
                      )}
                    </span>
                  )}
                </span>
              )).reduce((prev, curr) => [prev, prev.length > 0 ? ', ' : '', curr], [])}
              {hasGroupNotes && <div className="has-printable-value">{groupComments[group.slug]}</div>}
            </ReportTemplateBlock>
          </ReportTemplateBlock>
        </div>
      );
    })}
    {!items.length && !hasNotes && (
      <div className="nothing-to-display" onClick={() => setEditChecklistDialogIsOpen({componentUID, allowedSlugs, fetus})}>
        <ReportTemplateBlock props={{layout: "columns", "auto-hide": "true"}}>
          <ReportTemplateBlock props={{width: "20%"}}>
          </ReportTemplateBlock>
          <ReportTemplateBlock>
            {__("checklistItems.nothingToDisplay")}
          </ReportTemplateBlock>
        </ReportTemplateBlock>
      </div>
    )}
  </div>
});


export default withTranslation()(ReportTemplateChecklist);
