import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Button from "../../atoms/Button/Button";
import SelectInput from "../../atoms/SelectInput/SelectInput";
import { AppContext } from "../../context-providers/App";
import useAuth from "../../context-providers/Auth";
import { ExaminationContext } from "../../context-providers/Examination";
import useLatestVersion from "../../context-providers/LatestVersion";
import { formatName } from "../../services/examination";
import UserApi from "../../services/user";
import ExamPresetButtons from "../../components/ExamPresetButtons/ExamPresetButtons";
import "./LeftNavbar.css";
import Icon from "../../atoms/Icon/Icon";

const LeftNavbar = ({ t: __ }) => {
  const appContext = useContext(AppContext);
  const {
    user,
    sameSiteEntities,
    isManager,
    isShadow,
    switchBackToManager,
    switchUserAndRedirect,
    logout,
    isFeatureFlagEnabled
  } = useAuth();

  const examinationContext = useContext(ExaminationContext);
  const latestVersion = useLatestVersion();

  const history = useHistory();
  const location = useLocation();
  const { examId } = useParams();

  const [open, setOpen] = useState(false);
  const [openUser, setOpenUser] = useState(false);
  const [panelUrl, setPanelUrl] = useState("");

  const isSoniopedia = isFeatureFlagEnabled("soniopedia") && latestVersion.isSoniopediaURL()
  const isSdx = isFeatureFlagEnabled("sonio.dx_v2") && !isSoniopedia;
  const isPro = isFeatureFlagEnabled("sonio.routine") && !isSoniopedia;
  const isDt = isFeatureFlagEnabled("sonio.detect") && !isSoniopedia;
  const isDt2 = isFeatureFlagEnabled("sonio.detect_v2") && !isSoniopedia;
  const isDx = isSdx && (location.pathname.match(/^\/exam\/\d+\/dx/) || (location.pathname.match(/^\/exam-review\/\d+/) && examinationContext.examination.type === "diagnostic"));
  const defaultExamType = isSoniopedia ? "diagnostic"  : isSdx ? appContext.preferences.default_exam_type : "screening";

  let userManuals = [];
  if (isPro) userManuals.push({ label: "Sonio Pro", url: __('navbar.userManual.sx.href') });
  if (isDt) userManuals.push({ label: "Sonio Detect", url: __('navbar.userManual.dt.href') });
  if (isDx) userManuals.push({ label: "Sonio Diagnostics", url: __('navbar.userManual.dx.href') });
  if (isSoniopedia) userManuals = [{ label: "Soniopedia", url: __('navbar.userManual.soniopedia.href') }];

  let privacyPolicyUrl = __('navbar.privacyPolicy.sx.href');
  if (isDt) privacyPolicyUrl = __('navbar.privacyPolicy.dt.href')
  else if (isSdx && isDx) privacyPolicyUrl = __('navbar.privacyPolicy.sdx.href');
  else if (!isSdx && isDx) privacyPolicyUrl = __('navbar.privacyPolicy.dx.href');
  if (isSoniopedia) privacyPolicyUrl = __('navbar.privacyPolicy.soniopedia.href');

  let termsOfUseUrl = __('navbar.termsOfUse.sx.href');
  if (isDt) termsOfUseUrl = __('navbar.termsOfUse.dt.href')
  else if (isSdx && isDx) termsOfUseUrl = __('navbar.termsOfUse.sdx.href');
  else if (!isSdx && isDx) termsOfUseUrl = __('navbar.termsOfUse.dx.href');
  if (isSoniopedia) termsOfUseUrl = __('navbar.termsOfUse.soniopedia.href');

  let releaseNotesUrl = __('navbar.releaseNotes.sx.href');
  if (isDt) releaseNotesUrl = __('navbar.releaseNotes.dt.href')
  else if (isDx) releaseNotesUrl = __('navbar.releaseNotes.dx.href');
  else if (isSdx) releaseNotesUrl = __('navbar.releaseNotes.sdx.href');
  if (isSoniopedia) releaseNotesUrl = __('navbar.releaseNotes.soniopedia.href');

  useEffect(() => {
    if (!open) {
      setOpenUser(false);
      setPanelUrl("");
    }
  }, [open]);

  const toggleNavBar = () => {
    setOpen((open) => !open);
  }

  const endExamination = () => {
    if (examinationContext.endExamination) examinationContext.endExamination();
    setOpen(false);
  }

  const goToExamination = () => {
    setOpen(false);
    history.push(`/exam/${examinationContext.examination?.id || examId}`);
  }

  const goToDiagnostics = () => {
    setOpen(false);
    history.push(`/exam/${examinationContext.examination?.id || examId}/dx`);
  }

  const goToExamList = () => {
    setOpen(false);
    history.push(`/exams/`);
  }

  const goToPreferences = () => {
    setOpen(false);
    history.push(`/preferences/`);
  }

  const changeSite = (siteId) => {
    appContext.setSite(appContext.allSites.find(s => s.id === siteId));
    UserApi.changeSite(siteId).then(resp => window.location = "/");
  }

  return (
    <>
      <div className={`left-navbar-dt ${open ? 'open' : 'closed'}`}>
        <div className={`left-navbar-dt-panel ${!!panelUrl ? 'open' : 'closed'}`}>
          <div className="close" onClick={() => setPanelUrl("")}><Icon name="close" /></div>
          {!!panelUrl && (<iframe src={panelUrl} />)}
        </div>
        {appContext.goBackMenuButton ?
          <div className="toggle-menu go-back" onClick={!appContext.goBackMenuFunction ? history.goBack : appContext.goBackMenuFunction} >
            <Icon name="left" />
          </div>
          :
          <div className="toggle-menu" onClick={toggleNavBar} >
            <Icon name={!open ? "menu" : "left"} />
            {latestVersion.updateAvailable && <div className="update-available-indicator" />}
          </div>
        }
        <nav className={`left-navbar-dt-main ${open ? "open" : ""}`}>
          <div className="update-available-notif">
            {latestVersion.updateAvailable && <Button label={__("navbar.updateToVersion", { version: latestVersion.latestApiVersion })} size="medium" variant="outline" color="common" onClick={() => latestVersion.update()} />}
          </div>
          <ul className="margin-bottom-15">
            <li key="exams" onClick={() => setOpen(false)}>
              <a href="#" onClick={goToExamList}>{__("navbar.examList")}</a>
              <Icon name="right" />
            </li>
          </ul>

          {!isManager && !!examinationContext.examination && !!examinationContext.examination.id && (
            <div className="left-navbar-dt-current-examination">
              {!examinationContext.patient && (
                <h3>{__("navbar.currentExamination")}</h3>
              )}
              {!!examinationContext.patient && (
                <h3 className="ph-no-capture">
                  {formatName(examinationContext.patient.name).fullName}
                </h3>
              )}
              {!isSoniopedia && (<Button label={__("examination.goToExamination")} variant={location.pathname.match(/^\/exam\/\d+$/) ? "" : "outline"} color="common" icon="tiles" onClick={goToExamination} />)}
              {isSdx && (
                <Button label={__("examination.goToDiagnostics")} variant={location.pathname.match(/^\/exam\/\d+\/dx/) ? "" : "outline"} color="common" icon="position" onClick={goToDiagnostics} />
              )}
              {examinationContext.examination.status !== "completed" && (
                <Button label={__("examination.endExamination")} variant="outline" color="common" icon="tab" onClick={endExamination} />
              )}
            </div>
          )}

          {!isManager && (<div className={`left-navbar-dt-new-examination ${!!examinationContext?.examination?.id ? 'lowlight' : 'highlight'}`}>
            <ExamPresetButtons
              action="new-exam"
              label={defaultExamType === "diagnostic" ? __("navbar.startNewExaminationDx") : __("navbar.startNewExamination")}
              onChange={() => setOpen(false)}
              variant={!!examinationContext?.examination?.id ? "outline" : ""}
              theme="light"
            />
          </div>)}

          <div className="left-navbar-dt-separator" />
          <div className="left-navbar-dt-user-preview" onClick={() => {
            setPanelUrl("");
            return setOpenUser(openUser => !openUser);
          }}>
            <div className="left-navbar-dt-username">{user.title}</div>
            {appContext.site?.name && <div className="left-navbar-dt-sitename">{appContext.site.name}</div>}
            <div className="left-navbar-dt-preferences">{!openUser ? __("navbar.more") : __("navbar.less")} <Icon name={!openUser ? "right" : "left"} /></div>
          </div>
        </nav>
        <nav className={`left-navbar-dt-user ${openUser ? 'open' : ''}`}>
          <div className="left-navbar-dt-user-close" onClick={() => {
            setPanelUrl("");
            return setOpenUser(false);
          }}><Icon name="close" /></div>
          <div className="left-navbar-dt-user-top">
            <div className="left-navbar-dt-user-avatar">
              <Icon name="user" />
            </div>
            <div className="left-navbar-dt-username">{user.title}</div>
            {appContext.site && (
              <div className="left-navbar-dt-selector">
                <SelectInput
                  options={appContext.allSites.map(site => ({ label: site.name, value: site.id }))}
                  label={__("navbar.changeSite")}
                  value={appContext.site.id}
                  onChange={(value) => value !== appContext.site.id && !isShadow ? changeSite(parseInt(value)) : false}
                  theme="common-white"
                />
              </div>
            )}
            {(isManager || isShadow) && (
              <div className="left-navbar-dt-selector">
                <SelectInput
                  options={sameSiteEntities.map(({ title, id }) => ({ label: title, value: id }))}
                  label={__("navbar.switchUser")}
                  value={user.id}
                  onChange={(value) => (value !== user.id) && switchUserAndRedirect(value)}
                  theme="common-white"
                />
              </div>
            )}
            <div className="settings" onClick={goToPreferences}>
              <Icon name="settings" /> {__('navbar.preferences')}
            </div>

            {isShadow && (<div className="switch-user" onClick={() => switchBackToManager()}>
              <Icon name="switch" /> {__('navbar.switchBackToManager')}
            </div>)}

            <div className="logout" onClick={logout}>
              <Icon name="off" /> {__('navbar.logout')}
            </div>
          </div>

          <div className="left-navbar-dt-user-bottom">
            <div className="footer">
              <div className="update-available-notif">
                {isDt ? (
                  <div>Detect {isDt2 ? "v.2.4.0" : "v.1.6.0"}</div>
                ) : null}
                {isDx ? (
                  <div>Diagnostics v.2.5.1</div>
                ) : null}
                {isPro ? (
                  <div>Pro {latestVersion.currApiVersion}</div>
                ) : null}
                {isSoniopedia ? (
                  <div>Soniopedia v.1.0.0</div>
                ) : null}
              </div>
              <div className="regulatory-links">
                {userManuals.map(({ url, label }, i) => {
                  return (
                    <div onClick={() => setPanelUrl(panelUrl => !panelUrl || panelUrl !== url ? url : "")} key={i}>
                      {`${__('navbar.userManual')} ${label}`}
                    </div>
                  );
                })}
                <div onClick={() => setPanelUrl(panelUrl => !panelUrl || panelUrl !== privacyPolicyUrl ? privacyPolicyUrl : "")}>
                  {__('navbar.privacyPolicy')}
                </div>
                <div onClick={() => setPanelUrl(panelUrl => !panelUrl || panelUrl !== termsOfUseUrl ? termsOfUseUrl : "")}>
                  {__('navbar.termsOfUse')}
                </div>
                {!!releaseNotesUrl && (
                  <div onClick={() => setPanelUrl(panelUrl => !panelUrl || panelUrl !== releaseNotesUrl ? releaseNotesUrl : "")}>
                    {__('navbar.releaseNotes')}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={`left-navbar-regulatory-labels ${openUser ? 'open' : ''}`}>
            {isDx && (
              <div>
                <img src="/images/Sonio-Diag.svg" alt="Sonio Diagnostics" />
              </div>
            )}
            {(isDt && !isDt2) && (
              <div>
                <div className="regulatory-label ce-mark">
                  <b>UDI Detect v1.6.0</b><br/>
                  (01) 0 3 770024 341027 (10) Detect v1.6.0
                </div>
                <img src="/images/Sonio-Detect.svg" alt="FDA Mark: Sonio" />
              </div>
            )}
            {(isDt && isDt2) && (
              <div>
                <div className="regulatory-label ce-mark">
                  <b>UDI Detect v2.4.0</b><br/>
                  (01) 0 3 770024 341041 (10) Detect v2.4.0
                </div>
                <img src="/images/Sonio-Detect-v2.svg" alt="FDA Mark: Sonio" />
              </div>
            )}
            {isPro && (
              <div>
                <div className="regulatory-label ce-mark">
                  <b>UDI Pro {latestVersion.currApiVersion}</b><br/>
                  (01) 0 3 770024 341010 (10) Pro {latestVersion.currApiVersion}
                </div>
                <img src="/images/Sonio-Pro.svg" alt="Sonio Pro: MD" />
              </div>
            )}
          </div>
        </nav>
      </div>
      {open && (
        <div className="modal-background" onClick={() => toggleNavBar()} />
      )}
    </>
  );
};

export default withTranslation()(LeftNavbar);

LeftNavbar.propTypes = {
  t: PropTypes.any,
  i18n: PropTypes.any,
};
