import { useState, useRef, useEffect, useContext, cloneElement } from 'react';
import { withTranslation } from 'react-i18next';
import { ExaminationContext } from '../../context-providers/Examination';
import { XMLTemplateContext } from '../../context-providers/XMLTemplate';
import { convertTimeZone, formatYYYYMMDDDate, compareDates, generateRandomString } from "../../utils";
import useAuth from "../../context-providers/Auth";

import XMLToReact from 'xml-to-react';
import "./ExaminationReport.css";

import ReportTemplateBlock from './template/Block';
import ReportTemplateChecklist from './template/Checklist';
import ReportTemplateContent from './template/Content';
import ReportTemplateDocument from './template/Document';
import ReportTemplateHeader from './template/Header';
import ReportTemplateHeading from './template/Heading';
import ReportTemplateMeasurementCurve from './template/MeasurementCurve';
import ReportTemplateMeasurementCurveLegend from './template/MeasurementCurveLegend';
import ReportTemplateGaTable from './template/GaTable/GaTable';
import ReportTemplateBiometryTable from './template/BiometryTable';
import ReportTemplateBiometryItem from './template/BiometryItem';
import ReportTemplatePageBreak from './template/PageBreak';
import ReportTemplateSpacer from './template/Spacer';
import ReportTemplatePageFooter from './template/PageFooter';
import ReportTemplatePageHeader from './template/PageHeader';
import ReportTemplateLabel from './template/Label';
import ReportTemplateLogo from './template/Logo';
import ReportTemplateTextBlock from './template/TextBlock';
import ReportTemplateMedicalHistory from './template/MedicalHistory';
import ReportTemplateValue from './template/Value';
import ReportTemplateCondition from './template/Condition';
import ReportTemplateIf from './template/If';
import ReportTemplateElse from './template/Else';
import ReportTemplateImage from './template/Image';
import ReportTemplateImages from './template/Images';
import ReportTemplatePrivate from './template/Private';
import ReportTemplateDropdown from './template/Dropdown';
import ReportTemplateOption from './template/Option';
import ReportTemplateFetus from './template/Fetus';
import ReportTemplateTable from './template/Table';
import ReportTemplateGraphs from './template/Graphs';
import ReportTemplateFetalGrowthTable from './template/FetalGrowthTable';
import ReportTemplateEmpty from './template/Empty';
import ReportTemplateSearch from './template/Search';
import IdentifierBiometry from './template/IdentifierBiometry';

import Button from '../../atoms/Button/Button';
import ViewExamEventsDialog from './ViewExamEventsDialog';
import ViewExamStakeholdersDialog from './ViewExamStakeholdersDialog';
import PreviewExamReportDialog from './PreviewExamReportDialog';
import Icon from '../../atoms/Icon/Icon';
import { ExamStatus } from '../../config';
import ResourceApi from '../../services/resource';
import MeasurementsDialog from './MeasurementsDialog';
import EditChecklistItems from './editors/EditChecklistItems';
import { MeasurementsContext } from '../../context-providers/Measurements';
import SelectInput from '../../atoms/SelectInput/SelectInput';
import TextInput from '../../atoms/TextInput/TextInput';
import ArrangeOnTop from '../../atoms/ArrangeOnTop/ArrangeOnTop';
import { useXMLTemplate } from '../../context-providers/XMLTemplate';

const applyProps = (tree, templateTypeToProps) => {
  if (Array.isArray(tree))
    return tree.map((subTree) => applyProps(subTree, templateTypeToProps))
  if (tree === null)
    return null
  if (tree === undefined)
    return undefined
  if (tree.$$typeof) {
    if (!templateTypeToProps[tree.props.type]) {
      console.warn("Unkown report element", tree.type)
      return tree
    }
    const { children: treeChildren, ...treeProps } = tree.props
    const props = templateTypeToProps[tree.props.type](treeProps)
    const children = applyProps(treeChildren, templateTypeToProps)
    if (Array.isArray(children))
      return cloneElement.apply(null, [tree, props, ...children]);
    if (children === null || children === undefined)
      return cloneElement.apply(null, [tree, props]);
    return cloneElement.apply(null, [tree, props, children]);
  }
  if (typeof (tree) === 'string')
    return tree
  console.error("Unexpect tree element", tree)
  return tree
}

const applyType = (tree, templateTagToType) => {
  if (Array.isArray(tree))
    return tree.map((subTree) => applyType(subTree, templateTagToType))
  if (tree === null)
    return null
  if (tree === undefined)
    return undefined
  if (tree.$$typeof) {
    const { children: treeChildren } = tree.props
    const children = applyType(treeChildren, templateTagToType)
    if (!templateTagToType[tree.type]) {
      console.warn("Unkown report element", tree.type)
      return { ...tree, props: { ...tree.props, children, type: tree.type } }
    } else {
      return { ...tree, type: templateTagToType[tree.type], props: { ...tree.props, children, type: tree.type } }
    }
  }
  if (typeof (tree) === 'string')
    return tree
  console.error("Unexpect tree element", tree)
  return tree
}

const ExaminationReportView = ({
  t: __,
  isFeatureFlagEnabled,
  templateBlueprint,
  reportData,
  placeholders,
  setPlaceholders,
  zones,
  downloadReportAsPDF,
  downloadingPDF,
  editingFieldId,
  startEditingField,
  startEditing,
  onEndEditing,
  getPlaceholderWithProps,
  getCarryForwardWithProps,
  getHighligthedWithProps,
  getDocumentPlaceholder,
  base64Images,
  setBase64Images,
  setSubmitDialogIsOpen,
  submitDialogIsOpen,
  reviewReportDialogIsOpen,
  setReviewReportDialogIsOpen,
  editChecklistDialogIsOpen,
  setEditChecklistDialogIsOpen,
  setStakeholdersDialogIsOpen,
  previewExamReportDialogIsOpen,
  setPreviewExamReportDialogIsOpen,
  manageMeasurementsIsOpen,
  setManageMeasurementsIsOpen,
  commentValue,
  setCommentValue,
  submitReport,
  examStatus,
  examEvents,
  appContext,
  checkCondition,
  onEndEditingChecklist,
  onEndEditingDating,
  setAssignedGa,
  revertAssignedGa,
  associateContactPoint,
  deassociateContactPoint,
  updateEpisode,
  renderHTML,
  applyAutomationTemplate,
  automationTemplateFieldsVisible,
  setAutomationTemplateFieldsVisible,
  createAutomationTemplate,
  componentChecklistAssoc,
  addComponentChecklistAssoc,
  removeComponentChecklistAssoc,
}) => {
  const examinationContext = useContext(ExaminationContext);
  const measurementsContext = useContext(MeasurementsContext)
  const XMLTemplate = useContext(XMLTemplateContext);
  const { siteFlowsConnectors, user } = useAuth();
  const { generateAutomationTemplate } = useXMLTemplate();

  const [requiredAndInvalid, setRequiredAndInvalid] = useState(new Set());
  const [showErrors, setShowErrors] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [flashReportName, setFlashReportName] = useState("");
  const reportContentRef = useRef(null);

  let fetusNumber = 1;
  const numberOfFetuses = placeholders["patient.nb_fetuses"]?.value || 0;
  const fetusNames = placeholders["fetus.name"]?.value || [];
  const fetuses = [{ id: null }].concat(examinationContext.examination.fetuses);

  const commonProps = {
    reportMode: "edit",
    user,
    reportData,
    appPreferences: appContext?.preferences,
    examinationContext,
    canEdit: examinationContext.canEdit,
    requiredAndInvalid,
    setRequiredAndInvalid,
    showErrors,
    submitClicked,
    onEndEditing,
    numberOfFetuses,
    fetus: fetusNumber,
    fetuses,
    fetusNames,
    measurementsContext
  };

  const [plainTemplateReactTree, setTemplateReactTree] = useState(null);

  const supportedTags = [
    "snippet",
    "section",
    "block",
    "checklist",
    "column",
    "content",
    "labelled-content",
    "document",
    "dropdown",
    "fetus",
    "option",
    "condition",
    "if",
    "else",
    "image",
    "images",
    "label",
    "logo",
    "graphs",
    "group",
    "measurement-curve",
    "measurement-curve-legend",
    "ga-table",
    "biometry-table",
    "biometry-item",
    "fetal-growth-table",
    "header",
    "heading",
    "page-break",
    "private",
    "search",
    "spacer",
    "table",
    "textblock",
    "medical-history",
    "value",
    "page-footer",
    "page-header",
    "identifier-biometry",
  ]

  const templateTagToType = {
    snippet: ReportTemplateEmpty,
    section: ReportTemplateEmpty,
    block: ReportTemplateBlock,
    checklist: ReportTemplateChecklist,
    column: ReportTemplateEmpty,
    content: ReportTemplateContent,
    "labelled-content": ReportTemplateContent,
    document: ReportTemplateDocument,
    dropdown: ReportTemplateDropdown,
    fetus: ReportTemplateFetus,
    option: ReportTemplateOption,
    condition: ReportTemplateCondition,
    if: ReportTemplateIf,
    else: ReportTemplateElse,
    image: ReportTemplateImage,
    images: ReportTemplateImages,
    label: ReportTemplateLabel,
    logo: ReportTemplateLogo,
    graphs: ReportTemplateGraphs,
    group: ReportTemplateEmpty,
    "measurement-curve": ReportTemplateMeasurementCurve,
    "measurement-curve-legend": ReportTemplateMeasurementCurveLegend,
    "ga-table": ReportTemplateGaTable,
    "biometry-table": ReportTemplateBiometryTable,
    "biometry-item": ReportTemplateBiometryItem,
    "fetal-growth-table": ReportTemplateFetalGrowthTable,
    header: ReportTemplateHeader,
    heading: ReportTemplateHeading,
    "page-break": ReportTemplatePageBreak,
    private: ReportTemplatePrivate,
    search: ReportTemplateSearch,
    spacer: ReportTemplateSpacer,
    table: ReportTemplateTable,
    textblock: ReportTemplateTextBlock,
    "medical-history": ReportTemplateMedicalHistory,
    value: ReportTemplateValue,
    "page-footer": ReportTemplatePageFooter,
    "page-header": ReportTemplatePageHeader,
    "identifier-biometry": IdentifierBiometry,
  }


  const checklistItemsToDisplay = Object.entries(placeholders).filter(([key, value]) => key.startsWith("checklist.item.") && value.some(valuePerFetus => valuePerFetus.active)).map(item => item[1]);

  const checklistProps = {
    placeholders,
    groups: reportData.checklist_item_groups,
    checklistItems: checklistItemsToDisplay,
    getPlaceholderWithProps,
    setEditChecklistDialogIsOpen,
    zones,
    onEndEditingChecklist,
    componentChecklistAssoc,
    addComponentChecklistAssoc,
    removeComponentChecklistAssoc,
  }

  const dropdownProps = {
    loadDynamicDropdownFullTree: XMLTemplate.loadDynamicDropdownFullTree,
    onEndEditingDynamicDropdown: XMLTemplate.onEndEditingDynamicDropdown
  }

  const templateTypeToProps = {
    snippet: (props) => ({ props, type: "snippet", ...commonProps }),
    section: (props) => ({ props, type: "section", ...commonProps }),
    block: (props) => ({ props, ...commonProps }),
    checklist: (props) => ({ props, ...checklistProps, ...commonProps }),
    column: (props) => ({ props, type: "column", showOptions: false, ...commonProps }),
    content: (props) => ({ props, placeholder: getPlaceholderWithProps(props, "content"), ...commonProps }),
    "labelled-content": (props) => ({ props, placeholder: getPlaceholderWithProps(props, "content"), ...commonProps }),
    document: (props) => ({ props, startEditing, placeholder: getDocumentPlaceholder(siteFlowsConnectors?.coding), ...commonProps }),
    dropdown: (props) => ({ props, placeholder: getPlaceholderWithProps(props), highlight: getHighligthedWithProps(props), ...dropdownProps, ...commonProps }),
    fetus: (props) => ({ props, ...commonProps }),
    group: (props) => ({ props }),
    option: (props) => ({ props, type: "option", ...commonProps }),
    condition: (props) => ({ props, getPlaceholderWithProps, checkCondition, ...commonProps }),
    if: (props) => ({ props, getPlaceholderWithProps, type: "if", checkCondition, ...commonProps }),
    else: (props) => ({ props, type: "else", ...commonProps }),
    image: (props) => ({ props }),
    images: (props) => ({ props, placeholder: getPlaceholderWithProps(props), base64Images, setBase64Images }),
    label: (props) => ({ props }),
    logo: (props) => ({ props, url: placeholders["logo.url"] }),
    graphs: (props) => ({ props, ...commonProps }),
    "measurement-curve": (props) => ({ props, type: "measurement-curve", placeholder: placeholders[`measurement.${props.data}`], placeholders, editingFieldId, startEditingField, ...commonProps }),
    "measurement-curve-legend": (props) => ({ props, type: "measurement-curve-legend", numberOfFetuses, names: fetusNames, fetusNumber }),
    "ga-table": (props) => ({ props, placeholders, setPlaceholders, currentExamDate: reportData?.examination_data?.examination_date, onEndEditingDating, setAssignedGa, revertAssignedGa, updateEpisode, ...commonProps }),
    "biometry-table": (props) => ({ props, numberOfFetuses, fetusNumber, fetusNames }),
    "biometry-item": (props) => ({ props, reportData, placeholder: placeholders[`measurement.${props.data}`], placeholders, editingFieldId, startEditingField, setManageMeasurementsIsOpen, ...commonProps }),
    "fetal-growth-table": (props) => ({ props, placeholders, ...commonProps }),
    header: (props) => ({ props, reportData }),
    heading: (props) => ({ props }),
    "page-break": (props) => ({ props }),
    private: (props) => ({ props }),
    search: (props) => ({ props, placeholder: getPlaceholderWithProps(props), carryForward: getCarryForwardWithProps(props), ...commonProps }),
    spacer: (props) => ({ props }),
    table: (props) => ({ props, placeholder: getPlaceholderWithProps(props, "table"), carryForward: getCarryForwardWithProps(props, "table"), showOptions: false, ...dropdownProps, ...commonProps }),
    textblock: (props) => ({ props, placeholder: getPlaceholderWithProps(props, "textarea"), ...commonProps }),
    "medical-history": (props) => ({ props, medicalHistory: reportData.medical_history, isFeatureFlagEnabled }),
    value: (props) => ({ props, placeholder: getPlaceholderWithProps(props), ...commonProps }),
    "page-footer": (props) => ({ props }),
    "page-header": (props) => ({ props }),
    "identifier-biometry": (props) => ({ props, placeholders, ...commonProps }),
  }
  const onCloseWindow = () => {
    ResourceApi.createExaminationEvent(examinationContext.examination.id, "report_closed")
  }

  useEffect(() => {
    const xmlToReact = new XMLToReact(
      /* transform the list of supported tags to an object as:
       * {
       *    tag: (props) => ({type: tag, props})
       * }
       */
      supportedTags.reduce(
        (acc, tag) => ({
          ...acc,
          [tag]: (props) => ({ type: tag, props })
        }), {})
    )

    setTemplateReactTree(applyType(xmlToReact.convert(templateBlueprint), templateTagToType));
  }, [templateBlueprint]);

  useEffect(() => {
    window.addEventListener("beforeunload", onCloseWindow);
    ResourceApi.createExaminationEvent(examinationContext.examination.id, "report_opened")
    ResourceApi.createBIEvent({
      event_type: "report_opened",
      examination_id: examinationContext.examination.id,
      report_id: reportData.report.id
    })
    return () => {
      onCloseWindow();
      window.removeEventListener("beforeunload", onCloseWindow);
      ResourceApi.createExaminationEvent(examinationContext.examination.id, "report_closed")
      ResourceApi.createBIEvent({
        event_type: "report_closed",
        examination_id: examinationContext.examination.id,
        report_id: reportData.report.id
      })
    }
  }, []);

  const templateReactTree = applyProps(plainTemplateReactTree, templateTypeToProps)

  const examStatusLabel = () => {
    const ee = examEvents.filter(ee => ee.exam_status === examStatus).sort((a, b) => b.id - a.id)[0];
    const date = ee?.inserted_at + 'Z';

    const tz_date = formatYYYYMMDDDate(convertTimeZone(date, examinationContext.examination?.site?.timezone) || "", appContext.preferences.date_format);
    const tz_time = convertTimeZone(date, examinationContext.examination?.site?.timezone)?.substr(11, 5);

    const readyForReview = examinationContext.examination.status === ExamStatus.READY_FOR_REVIEW;
    const submittedOn = examinationContext.examination.events.sort((a, b) => a.inserted_at < b.inserted_at ? 1 : -1).find(event => event.event_type === "status_update" && event.exam_status === ExamStatus.READY_FOR_REVIEW)?.inserted_at;
    const lastDicomTime = examinationContext.examination.events.sort((a, b) => a.inserted_at < b.inserted_at ? 1 : -1).find(event => event.event_type === "dicom")?.inserted_at;

    return <div className="exam-report-status">
      {__("examinationReview.status." + examStatus)}.&nbsp;
      {tz_date && tz_time && __("examinationReview.status." + examStatus + ".meta", { date: tz_date, time: tz_time, name: ee?.entity?.title })}
      {!examinationContext.canEdit &&
        <div>
          <Icon name="lock_closed" /> {__("examinationReview.examIsLocked")}
          {examinationContext.canUnfreeze && <>
            {__(":")} <Button label={__("examinationReview.editExam")} variant="link" size="small" onClick={() => examinationContext.reopenExamination()} />
          </>}
        </div>
      }
      {
        readyForReview
        && compareDates(submittedOn, lastDicomTime) === -1
        && (
          <div className="exam-completed-status-warning">
            <Icon name="warning" />
            {__("examinationReview.dicomReceivedAfterSubmission")}
          </div>
        )
      }
    </div>;
  };

  // Handle missing required fields
  const scrollToFirstError = () => {
    const firstErrorElem = reportContentRef.current.getElementsByClassName('required-error')[0];
    if (firstErrorElem)
      firstErrorElem.scrollIntoView({ behavior: "smooth", block: 'nearest' }); // 'nearest' needed because scrolling to the end of the report display the notification panel
  };

  const onClickSubmit = (newStatus) => {
    setShowErrors(false);
    setSubmitClicked(true);
    const isCodingProvided = (examinationContext.examination?.procedures_codes?.length != 0) && (examinationContext.examination?.diagnoses_codes?.length != 0)
    const isCodingCorrectlyProvided = (examinationContext.examination?.preset?.is_coding_mandatory && isCodingProvided) || !examinationContext.examination?.preset?.is_coding_mandatory || !siteFlowsConnectors?.coding

    /* Keep this to understand why we can't sign sometimes */
    if (!isCodingCorrectlyProvided)
      console.warn("Coding is not correctly filled in. Please fill it before submitting")
    if (requiredAndInvalid.size !== 0)
      console.warn("Some mandatory fields are not correctly provided.", requiredAndInvalid)
    if (requiredAndInvalid.size === 0 && isCodingCorrectlyProvided) {
      setSubmitDialogIsOpen(newStatus);
      return;
    }
    setShowErrors(true);
  };

  useEffect(() => {
    if (showErrors && submitClicked) {
      setSubmitClicked(false);
      scrollToFirstError();
    }
  }, [showErrors, submitClicked]);

  const getAutomationTemplateOptions = () => {
    if (automationTemplateFieldsVisible) return [];

    let options = reportData.automation_templates?.map(t => ({ label: t.name, value: t.slug })) || [];

    if (isFeatureFlagEnabled("sonio.create_quick_reports")) {
      if (reportData.automation_templates?.length > 0) options.push({ type: "separator" });

      options.push({ label: "Add new", value: "add-new-flash-report" });
    }

    return options;
  }

  const closeNewFlashReportMode = () => {
    setFlashReportName("");
    setAutomationTemplateFieldsVisible(false);
  }

  const handleSaveFlashReport = () => {
    generateAutomationTemplate().then(template => {
      createAutomationTemplate({
        slug: `${flashReportName.toLowerCase().replaceAll(" ", "-")}-${generateRandomString()}`,
        name: flashReportName,
        template: template
      })
    });

    closeNewFlashReportMode();
  };

  return <div className={`exam-report-wrapper ${examinationContext.canEdit ? '' : 'frozen'}`}>
    <div ref={reportContentRef} className="exam-report-content">
      {!examinationContext.canEdit && <div className="exam-report-frozen-warning" onClick={() => examinationContext.reopenExamination()}><div /></div>}
      {templateReactTree}
    </div>
    <div className="exam-report-options">
      <div className="exam-report-options-column">
        <Button variant="outline" onClick={() => setReviewReportDialogIsOpen(true)} icon="comment" label={__("examinationReview.dialog.activity")} />
        <Button variant="outline" onClick={() => setManageMeasurementsIsOpen(true)} onClickDisabled={examinationContext.reopenExamination} icon="settings-alt" label={__("examinationReview.dialog.measurements")} disabled={!examinationContext.canEdit} />
        <Button variant="outline" onClick={() => setEditChecklistDialogIsOpen(true)} onClickDisabled={() => examinationContext.reopenExamination()} icon="done" label={__("examinationReview.dialog.checklist")} disabled={!examinationContext.canEdit} />
        {isFeatureFlagEnabled("sonio.quick_reports") && (
          <div>
            <SelectInput
              options={getAutomationTemplateOptions()}
              onChange={(slug) => slug === "add-new-flash-report"
                ? setAutomationTemplateFieldsVisible(true)
                : applyAutomationTemplate(slug)
              }
              placeholder={{ icon: "flash", label: "" }}
              showDropDown={false}
              variant="outline"
              padding="big"
              disabled={!examinationContext.canEdit || automationTemplateFieldsVisible}
            />
            {automationTemplateFieldsVisible && (
              <ArrangeOnTop variant="balloon">
                <div className="exam-report-options_create-new-flash-report">
                  <TextInput placeholder="Flash Report Name" value={flashReportName} onChange={setFlashReportName} />
                  <Button size="input" icon="flash" label="Save" onClick={handleSaveFlashReport} />
                  <Button size="input" variant="outline" label="Cancel" onClick={() => setAutomationTemplateFieldsVisible(false)} />
                </div>
              </ArrangeOnTop>
            )}
          </div>
        )}

        {examStatusLabel()}

      </div>
      <div className="exam-report-options-column">
        <Button variant="outline" onClick={() => setPreviewExamReportDialogIsOpen(true)} icon="download" label={__("examinationReview.dialog.previewDownloadPdf")} />
        {/* {uploadReportAsPDF ? <Button variant="link" onClick={uploadReportAsPDF} disabled={uploadingReportAsPDF} icon="download" label={uploadingReportAsPDF ? __("examinationReview.dialog.uploading") : __("examinationReview.dialog.uploadPdf")} /> : null} */}
        {examinationContext.canSubmit && !examinationContext.canSign && <Button onClick={() => onClickSubmit(ExamStatus.READY_FOR_REVIEW)} icon="edit" label={__("common.submit")} />}
        {examinationContext.canSign && <Button onClick={() => onClickSubmit(ExamStatus.REPORT_SIGNED)} icon="edit" label={__("examinationReview.dialog.signReport")} />}
      </div >
    </div >
    {submitDialogIsOpen &&
      <ViewExamStakeholdersDialog
        submitDialogIsOpen={submitDialogIsOpen}
        setSubmitDialogIsOpen={setSubmitDialogIsOpen}
        setStakeholdersDialogIsOpen={setStakeholdersDialogIsOpen}
        commentValue={commentValue}
        setCommentValue={setCommentValue}
        examEvents={examEvents}
        submitReport={submitReport}
        examination={examinationContext.examination}
        reportData={reportData}
        associateEntity={examinationContext.associateEntity}
        deassociateEntity={examinationContext.deassociateEntity}
        associateContactPoint={associateContactPoint}
        deassociateContactPoint={deassociateContactPoint}
      />
    }

    {examinationContext.canEdit && manageMeasurementsIsOpen &&
      <MeasurementsDialog
        manageMeasurementsIsOpen={manageMeasurementsIsOpen}
        setManageMeasurementsIsOpen={setManageMeasurementsIsOpen}
      />
    }

    {reviewReportDialogIsOpen &&
      <ViewExamEventsDialog
        setReviewReportDialogIsOpen={setReviewReportDialogIsOpen}
        commentValue={commentValue}
        setCommentValue={setCommentValue}
        examEvents={examEvents}
        submitReport={submitReport}
        examination={examinationContext.examination}
        updateExamination={examinationContext.updateExamination}
      />
    }

    {previewExamReportDialogIsOpen &&
      <PreviewExamReportDialog
        setPreviewExamReportDialogIsOpen={setPreviewExamReportDialogIsOpen}
        renderHTML={renderHTML}
        downloadReportAsPDF={downloadReportAsPDF}
        downloadingPDF={downloadingPDF}
      />
    }

    {examinationContext.canEdit && editChecklistDialogIsOpen && (
      <EditChecklistItems
        fetus={editChecklistDialogIsOpen?.fetus ?? 1}
        fetuses={fetuses}
        groups={reportData.checklist_item_groups}
        items={checklistItemsToDisplay.filter(item => editChecklistDialogIsOpen.allowedSlugs ? editChecklistDialogIsOpen.allowedSlugs.includes(item[editChecklistDialogIsOpen?.fetus ?? 1]?.slug) : true)}
        initialGroup={editChecklistDialogIsOpen?.slug}
        componentChecklistAssoc={editChecklistDialogIsOpen.componentUID ? componentChecklistAssoc[editChecklistDialogIsOpen.componentUID] : false}
        componentUID={Object.keys(componentChecklistAssoc).length === 1 ? Object.keys(componentChecklistAssoc)[0] : editChecklistDialogIsOpen.componentUID}
        close={() => setEditChecklistDialogIsOpen(false)}
      />
    )}
  </div >;
};

export default withTranslation()(ExaminationReportView);





