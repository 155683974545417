import { withTranslation } from "react-i18next";
import React, {useState, useContext} from "react";
import ResourceApi from "../../services/resource";
import { AppContext } from "../../context-providers/App";
import "./PrintingSharingSettings.css";
import useAnchorScroll from "../../hooks/useAnchorScroll";
import ImagePreview from "./ImagePreview";
import ImageSettingField from "./ImageSettingField";

const MAX_SIZE_IN_BYTES = 5e6; //5MB ~ 5,000,000 bytes

const HeaderImageSettings = ({ t: __ }) => {
  useAnchorScroll();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const { site, setSite } = useContext(AppContext);

  const handleImageChange = async (e) => {
    const files = e?.target?.files ?? [];
    if (files.length > 0 && files[0].size <= MAX_SIZE_IN_BYTES) {
      setErrorMessage(false);
      setLoading(true);
      const newFile = files[0];
      const formData = new FormData();
      formData.append("header_image", newFile);
      try {
        const res = await ResourceApi.uploadBrandImage(formData);
        if(res?.data?.error == null) {
          setSite(res.data.data);
        }
      } catch(e) {
        console.error(e);
        setErrorMessage(__("preferences.printingSharing.logo.uploadError"))
      }
      setLoading(false);
    } else {
      setErrorMessage(__("preferences.printingSharing.logo.uploadTooBig"))
    }
  }

  const handleDeleteImage = async () => {
    setLoading(true)
    try {
      const res = await ResourceApi.updateSite(site?.id, 
        { header_filename: null }
      )
      if(res?.data?.error == null) {
        setSite(res.data.data);
      }
    } catch(e){
      console.error(e);
    }
    setLoading(false);
  }


  return(
    <ImageSettingField
      idSlug="header-image"
      title={__("preferences.printingSharing.headerImage")}
      subTitle={__("preferences.printingSharing.headerUploadInfo")}
      description={__("preferences.printingSharing.headerDescription")}
      uploadButtonText={__("preferences.printingSharing.headerUploadText")}
      onChange={handleImageChange}
      onDelete={handleDeleteImage}
      errorMessage={errorMessage}
    >
      <ImagePreview loading={loading} b64Data={site.header_image_base64} />
    </ImageSettingField>
  )
};

export default withTranslation()(HeaderImageSettings);
