import PropTypes from "prop-types";
import { useContext, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import Button from "../../atoms/Button/Button";
import Icon from "../../atoms/Icon/Icon";
import SelectInput from "../../atoms/SelectInput/SelectInput";
import { AppContext } from "../../context-providers/App";
import { WindowMessagingContext } from "../../context-providers/WindowMessaging";
import useAuth from "../../context-providers/Auth";
import { ExaminationContext } from "../../context-providers/Examination";
import useLatestVersion from "../../context-providers/LatestVersion";
import { formatName } from "../../services/examination";
import UserApi from "../../services/user";
import ExamPresetButtons from "../ExamPresetButtons/ExamPresetButtons";
import "./LeftNavbar.css";
import ResourceApi from "../../services/resource";
import { WindowContext } from "../../context-providers/Window";

const LeftNavbar = ({ t: __ }) => {
  const appContext = useContext(AppContext);
  const windowMessagingContext = useContext(WindowMessagingContext);
  const {
    user,
    sameSiteEntities,
    isManager,
    isShadow,
    switchBackToManager,
    switchUserAndRedirect,
    logout,
    isFeatureFlagEnabled,
    grants,
    grantId,
    siteId
  } = useAuth();

  const windowContext = useContext(WindowContext);
  const examinationContext = useContext(ExaminationContext);
  const latestVersion = useLatestVersion();

  const history = useHistory();
  const location = useLocation();
  const { examId } = useParams();

  const [open, setOpen] = useState(false);
  const [openUser, setOpenUser] = useState(false);
  const [panelUrl, setPanelUrl] = useState("");
  const [sitesForGrant, setSitesForGrant] = useState([]);
  const [savingWorkspace, setSavingWorkspace] = useState(false);

  const isSoniopedia = isFeatureFlagEnabled("soniopedia") && latestVersion.isSoniopediaURL()
  const isSdx = isFeatureFlagEnabled("sonio.dx_v2") && !isSoniopedia;
  const isDt = isFeatureFlagEnabled("sonio.detect") && !isSoniopedia;
  const isDt2 = isFeatureFlagEnabled("sonio.detect_v2") && !isSoniopedia;
  const isPro = !isSoniopedia;
  const isDx = isSdx && (location.pathname.match(/^\/exam\/\d+\/dx/) || (location.pathname.match(/^\/exam-review\/\d+/) && examinationContext.examination.type === "diagnostic"));
  const defaultExamType = isSoniopedia ? "diagnostic"  : isSdx ? appContext.preferences.default_exam_type : "screening";

  useEffect(() => {
    ResourceApi.listSitesForGrant(grantId).then((resp) =>
      setSitesForGrant(resp.data.data)
    );
  }, [grantId]);
  
  // Group by practice to display the practice names in the site dropdown. 
  // Do this only if all the sites have a valid practice_id on them (for backwards compatibility).
  let siteDropdownOptions = [];
  if (appContext.allSites?.every((site) => site.practice_id)) {
    let sitesGroupedByPractice = appContext.allSites?.reduce((acc, site) => {
      const key = site.practice_id?.toString();
  
      if (Object.keys(acc).includes(key)) {
        acc[key].push(site);
      } else {
        acc[key] = [site];
      }
      return acc;
    }, {}) || {};
    
    siteDropdownOptions = Object.keys(sitesGroupedByPractice).reduce((acc, practiceId) => {
      const practice = sitesGroupedByPractice[practiceId][0].practice;
      acc.push({ type: "heading", label: practice.name });
      sitesGroupedByPractice[practiceId].forEach((site) => acc.push({ type: "option", label: site.name, value: site.id }));
      return acc;
    }, []) || [];
  } else {
    siteDropdownOptions = appContext.allSites?.map((site) => ({ type: "option", label: site.name, value: site.id })) || [];
  }
  
  let userManuals = [{ label: "Sonio Pro", url: __('navbar.userManual.sx.href') }];
  if (isDt) userManuals.push({ label: "Sonio Detect", url: isDt2 ? __('navbar.userManual.dt2.href') : __('navbar.userManual.dt.href') });
  if (isDx) userManuals.push({ label: "Sonio Diagnostics", url: __('navbar.userManual.dx.href') });
  if (isSoniopedia) userManuals = [{ label: "Soniopedia", url: __('navbar.userManual.soniopedia.href') }];

  let roleDropdownOptions = useMemo(() => {
    let options = [];
    grants.forEach((g) => {
      let resource = "";
      let icon = "";
      
      if (g.site_id) {
        resource = g.site.name;
        icon = "location";
      } else if (g.practice_id) {
        resource = g.practice.name;
        icon = "distance";
      } else if (g.organization_id) {
        resource = g.organization.name;
        icon = "world";
      }

      options.push({ type: "option", icon: icon, label: `${g.role?.label} on ${resource}`, value: g.id });
    });

    return options;
  }, [grants]);

  let sitesForGrantDropdownOptions = useMemo(() => {
    let options = [];
    sitesForGrant.forEach((s) => {
      options.push({ type: "option", label: s.name, value: s.id });
    });

    return options;
  }, [sitesForGrant]);

  let privacyPolicyUrl = __('navbar.privacyPolicy.sx.href');
  if (isDt) privacyPolicyUrl = isDt2 ? __('navbar.privacyPolicy.dt2.href') : __('navbar.privacyPolicy.dt.href');
  else if (isDx) privacyPolicyUrl = __('navbar.privacyPolicy.dx.href');
  else if (isSdx) privacyPolicyUrl = __('navbar.privacyPolicy.sdx.href');
  if (isSoniopedia) privacyPolicyUrl = __('navbar.privacyPolicy.soniopedia.href');

  let termsOfUseUrl = __('navbar.termsOfUse.sx.href');
  if (isDt) termsOfUseUrl = isDt2 ? __('navbar.termsOfUse.dt2.href') : __('navbar.termsOfUse.dt.href');
  else if (isDx) termsOfUseUrl = __('navbar.termsOfUse.dx.href');
  else if (isSdx) termsOfUseUrl = __('navbar.termsOfUse.sdx.href');
  if (isSoniopedia) termsOfUseUrl = __('navbar.termsOfUse.soniopedia.href');

  let releaseNotesUrl = __('navbar.releaseNotes.sx.href');
  if (isDt) releaseNotesUrl = isDt2 ? __('navbar.releaseNotes.dt2.href') : __('navbar.releaseNotes.dt.href');
  else if (isDx) releaseNotesUrl = __('navbar.releaseNotes.dx.href');
  else if (isSdx) releaseNotesUrl = __('navbar.releaseNotes.sdx.href');
  if (isSoniopedia) releaseNotesUrl = __('navbar.releaseNotes.soniopedia.href');

  useEffect(() => {
    if (!open) {
      setOpenUser(false);
      setPanelUrl("");
    }
  }, [open]);

  const backToDashboard = () => {
    if (isFeatureFlagEnabled("soniopedia")) {
      history.push("/");
      toggleNavBar();
    } else {
      /* Rely on the redirection in router.ex */
      window.location.href = "/dashboard"
    }
  }

  const toggleNavBar = () => {
    setOpen((open) => !open);
  }

  const endExamination = () => {
    if (examinationContext.endExamination) examinationContext.endExamination();
    setOpen(false);
  }

  const goToExamination = () => {
    setOpen(false);
    history.push(`/exam/${examinationContext.examination?.id || examId}`);
  }

  const goToDiagnostics = () => {
    setOpen(false);
    history.push(`/exam/${examinationContext.examination?.id || examId}/dx`);
  }

  const goToMedicalHistory = () => {
    setOpen(false);
    history.push(`/exam-anamnesis/${examinationContext.examination?.id || examId}`);
  }

  const goToPatientRecord = () => {
    setOpen(false);
    if (examinationContext.patient && !isSoniopedia) {
      window.location.href = `/patient/${examinationContext.patient.id}`;
    }
  }

  const goToReport = () => {
    setOpen(false);
    history.push(examinationContext.examination.type === "diagnostic" ? `/exam-review/${examinationContext.examination?.id || examId}` : `/exam/${examinationContext.examination?.id || examId}#report`);
  }

  const goToPreferences = () => {
    setOpen(false);
    history.push(`/preferences/`);
  }

  const changeSite = (siteId) => {
    appContext.setSite(appContext.allSites.find(s => s.id === siteId));
    UserApi.changeSite(siteId).then(resp => window.location = "/");
  }

  const changeGrantForSession = (newGrantId) => {
    UserApi.changeGrantForSession(newGrantId).then(resp => window.location = "/");
  }

  const changeSiteForSession = (siteId) => {
    UserApi.changeSiteForSession(siteId).then(resp => window.location = "/");;
  }


  if(windowMessagingContext.liveContext.hideLeftNavBar)
    return null

  return (
    <>
      <div className={`left-navbar ${open ? 'open' : 'closed'}`} data-test-id={open ? 'side-menu-close' : 'side-menu-hamburger'}>
        <div className={`left-navbar-panel ${!!panelUrl ? 'open' : 'closed'}`}>
          <div className="close" onClick={() => setPanelUrl("")}><Icon name="close" /></div>
          {!!panelUrl && (<iframe src={panelUrl} />)}
        </div>
        {appContext.goBackMenuButton ?
          <div className="toggle-menu go-back" onClick={!appContext.goBackMenuFunction ? history.goBack : appContext.goBackMenuFunction} >
            <Icon name="left" />
          </div>
          :
          <div className="toggle-menu" onClick={toggleNavBar} >
            <Icon name={!open ? "menu" : "left"} />
            {latestVersion.updateAvailable && <div className="update-available-indicator" />}
          </div>
        }
        <nav className={`left-navbar-main ${open ? "open" : ""}`}>
          <div className="update-available-notif">
            {latestVersion.updateAvailable && <Button label={__("navbar.updateToVersion", { version: latestVersion.latestApiVersion })} size="medium" variant="outline" color="common" onClick={() => latestVersion.update()} />}
          </div>
          <ul className="margin-bottom-15">
            {!isManager && (<li key="dashboard">
              <a data-test-id="side-menu-dashboard" onClick={backToDashboard}>{__("navbar.dashboard")}</a>
              <Icon name="right" />
            </li>)}
            {(!isManager && !isSoniopedia) && (<li key="patients" onClick={() => setOpen(false)}>
              <Link data-test-id="side-menu-patients" to="/patients">{__("navbar.patients")}</Link>
              <Icon name="right" />
            </li>)}
          </ul>

          {!isManager && !!examinationContext.examination && !!examinationContext.examination.id && (
            <div className="left-navbar-current-examination">
              {!examinationContext.patient && (
                <h3>{__("navbar.currentExamination")}</h3>
              )}
              {!!examinationContext.patient && (
                <h3 className="ph-no-capture" onClick={goToPatientRecord}>
                  {formatName(examinationContext.patient.name).fullName}
                </h3>
              )}
              {(isSdx || isSoniopedia) && (
                <Button label={__("examination.medicalHistory")} variant={location.pathname.match(/^\/exam-anamnesis\/\d+$/) ? "" : "outline"} color="common" icon="paste" onClick={goToMedicalHistory} />
              )}
              {!isSoniopedia && (<Button data-test-id="side-menu-examination" label={__("examination.goToExamination")} variant={location.pathname.match(/^\/exam\/\d+$/) ? "" : "outline"} color="common" icon="tiles" onClick={goToExamination} />)}
              {(isSdx || isSoniopedia) && (
                <Button label={__("examination.goToDiagnostics")} variant={location.pathname.match(/^\/exam\/\d+\/dx/) ? "" : "outline"} color="common" icon="position" onClick={goToDiagnostics} />
              )}
              {!examinationContext.isCompleted() ? (
                <Button data-test-id="side-menu-end-examination" label={__("examination.endExamination")} variant="outline" color="common" icon="tab" onClick={endExamination} />
              ) : appContext.protocolsEnabled && (
                <Button data-test-id="side-menu-goto-report" label={__("examination.goToReport")} variant={location.pathname.match(/^\/exam-review\/\d+/) ? "" : "outline"} color="common" icon="stats" onClick={goToReport} />
              )}
            </div>
          )}

          {!isManager && examinationContext?.canCreateAnnonymousExam() && (<div className={`left-navbar-new-examination ${!!examinationContext?.examination?.id ? 'lowlight' : 'highlight'}`}>
            <ExamPresetButtons
              action="new-exam"
              label={defaultExamType === "diagnostic" ? __("navbar.startNewExaminationDx") : __("navbar.startNewExamination")}
              onChange={() => setOpen(false)}
              variant={!!examinationContext?.examination?.id ? "outline" : ""}
              theme="light"
            />
          </div>)}

          <div className="left-navbar-separator" />
          <div className="left-navbar-user-preview" onClick={() => {
            setPanelUrl("");
            return setOpenUser(openUser => !openUser);
          }}>
            <div className="left-navbar-username">{user.title}</div>
            {appContext.site?.name && <div className="left-navbar-sitename">{appContext.site.name}</div>}
            <div className="left-navbar-preferences">{!openUser ? __("navbar.more") : __("navbar.less")} <Icon name={!openUser ? "right" : "left"} /></div>
          </div>
        </nav>
        <nav className={`left-navbar-user ${openUser ? 'open' : ''}`}>
          <div className="left-navbar-user-close" onClick={() => {
            setPanelUrl("");
            return setOpenUser(false);
          }}><Icon name="close" /></div>
          <div className="left-navbar-user-top">
            <div className="left-navbar-user-avatar">
              <Icon name="user" />
            </div>
            <div className="left-navbar-username">{user.title}</div>
            {/* {appContext.site && (
              <div className="left-navbar-selector">
                <SelectInput
                  options={siteDropdownOptions}
                  label={__("navbar.changeSite")}
                  value={appContext.site.id}
                  onChange={(value) => value !== appContext.site.id && !isShadow ? changeSite(parseInt(value)) : false}
                  theme="common-white"
                />
              </div>
            )} */}
            {roleDropdownOptions.length > 1 && <div className="left-navbar-selector">
              <SelectInput
                options={roleDropdownOptions}
                label={__("navbar.changeRole")}
                value={grantId}
                onChange={(value) => changeGrantForSession(parseInt(value))}
                theme="common-white"
              />
            </div>}
            {sitesForGrantDropdownOptions.length > 1 && <div className="left-navbar-selector">
              <SelectInput
                options={sitesForGrantDropdownOptions}
                label={"Default Site"}
                value={siteId}
                onChange={(value) => changeSiteForSession(parseInt(value))}
                theme="common-white"
              />
            </div>}
            {(isManager || isShadow) && (
              <div className="left-navbar-selector">
                <SelectInput
                  options={sameSiteEntities.map(({ title, id }) => ({ label: title, value: id }))}
                  label={__("navbar.switchUser")}
                  value={user.id}
                  onChange={(value) => (value !== user.id) && switchUserAndRedirect(value)}
                  theme="common-white"
                />
              </div>
            )}
            <div className="settings" onClick={goToPreferences}>
              <Icon name="settings" /> {__('navbar.preferences')}
            </div>

            {isFeatureFlagEnabled("sonio.multiscreen") && (
              <div className="multiscreen">
                <Button icon="workspace" label={__(savingWorkspace ? 'navbar.multiscreen.saved' : 'navbar.multiscreen.save')} variant="outline" size="small" color="common" onClick={() => {
                  setSavingWorkspace(true);
                  windowContext.saveConfig();
                  setTimeout(() => setSavingWorkspace(false), 1000);
                }} />
                {windowContext.hasConfig && (
                  <Button icon="workspace" label={__(savingWorkspace ? 'navbar.multiscreen.clean' : 'navbar.multiscreen.clean')} variant="outline" size="small" color="common" onClick={() => {
                    setSavingWorkspace(true);
                    windowContext.cleanConfig();
                    setTimeout(() => setSavingWorkspace(false), 1000);
                  }} />
                )}
                {!windowContext.hasConfig && (
                  <div><small><Icon name="info" /> {__('navbar.multiscreen.hints')}</small></div>
                )}
              </div>
            )}

            {isShadow && (<div className="switch-user" onClick={() => switchBackToManager()}>
              <Icon name="switch" /> {__('navbar.switchBackToManager')}
            </div>)}

            <div className="logout" onClick={logout}>
              <Icon name="off" /> {__('navbar.logout')}
            </div>
          </div>

          <div className="left-navbar-user-bottom">
            <div className="footer">
              <div className="update-available-notif">
                {isDt ? (
                  <div>Detect {isDt2 ? "v.2.4.0" : "v.1.6.0"}</div>
                ) : null}
                {isDx ? (
                  <div>Diagnostics v.2.5.1</div>
                ) : null}
                {isPro ? (
                  <div>Pro {latestVersion.currApiVersion}</div>
                ) : null}
                {isSoniopedia ? (
                  <div>Soniopedia v.1.0.0</div>
                ) : null}
              </div>
              <div className="regulatory-links">
                {userManuals.map(({ url, label }, i) => {
                  return (
                    <div onClick={() => setPanelUrl(panelUrl => !panelUrl || panelUrl !== url ? url : "")} key={i}>
                      {`${__('navbar.userManual')} ${label}`}
                    </div>
                  );
                })}
                <div onClick={() => setPanelUrl(panelUrl => !panelUrl || panelUrl !== privacyPolicyUrl ? privacyPolicyUrl : "")}>
                  {__('navbar.privacyPolicy')}
                </div>
                <div onClick={() => setPanelUrl(panelUrl => !panelUrl || panelUrl !== termsOfUseUrl ? termsOfUseUrl : "")}>
                  {__('navbar.termsOfUse')}
                </div>
                {!!releaseNotesUrl && (
                  <div onClick={() => setPanelUrl(panelUrl => !panelUrl || panelUrl !== releaseNotesUrl ? releaseNotesUrl : "")}>
                    {__('navbar.releaseNotes')}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={`left-navbar-regulatory-labels ${openUser ? 'open' : ''}`}>
            {isDx && (
              <div>
                <img src="/images/Sonio-Diag.svg" alt="Sonio Diagnostics" />
              </div>
            )}
            {(isDt && !isDt2) && (
              <div>
                <div className="regulatory-label ce-mark">
                  <b>UDI Detect v1.6.0</b><br/>
                  (01) 0 3 770024 341027 (10) Detect v1.6.0
                </div>
                <img src="/images/Sonio-Detect.svg" alt="FDA Mark: Sonio" />
              </div>
            )}
            {(isDt && isDt2) && (
              <div>
                <div className="regulatory-label ce-mark">
                  <b>UDI Detect v2.4.0</b><br/>
                  (01) 0 3 770024 341041 (10) Detect v2.4.0
                </div>
                <img src="/images/Sonio-Detect-v2.svg" alt="FDA Mark: Sonio" />
              </div>
            )}
            {isPro && (
              <div>
                <div className="regulatory-label ce-mark">
                  <b>UDI Pro {latestVersion.currApiVersion}</b><br/>
                  (01) 0 3 770024 341010 (10) Pro {latestVersion.currApiVersion}
                </div>
                <img src="/images/Sonio-Pro.svg" alt="Sonio Pro: MD" />
              </div>
            )}
          </div>
        </nav>
      </div>
      {open && (
        <div className="modal-background" onClick={() => toggleNavBar()} />
      )}
    </>
  );
};

export default withTranslation()(LeftNavbar);

LeftNavbar.propTypes = {
  t: PropTypes.any,
  i18n: PropTypes.any,
};
